@import "~bootstrap/dist/css/bootstrap.css"; // BOOTSTRAP 5.0
@import "@angular/material/prebuilt-themes/indigo-pink.css"; // ANGULAR MATERIAL
@import "ag-grid-community/dist/styles/ag-grid.css"; // FOR AG-GRID ANGULAR
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ngx-toastr/toastr"; // FOR NGX-TOASTR

@import "./theme.scss";

// GE FONT CONFIGURATION
@font-face {
  font-family: "geSans_regular";
  src: url("./assets/fonts/GESansRegular.eot");
  src: url("./assets/fonts/GESansRegular.eot") format("embedded-opentype"),
    url("./assets/fonts/GESansRegular.woff2") format("woff2"),
    url("./assets/fonts/GESansRegular.woff") format("woff"),
    url("./assets/fonts/GESansRegular.ttf") format("truetype"),
    url("./assets/fonts/GESansRegular.svg#geSans_regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "geSans_bold";
  src: url("./assets/fonts/GESansBold.eot");
  src: url("./assets/fonts/GESansBold.eot") format("embedded-opentype"),
    url("./assets/fonts/GESansBold.woff2") format("woff2"),
    url("./assets/fonts/GESansBold.woff") format("woff"),
    url("./assets/fonts/GESansBold.ttf") format("truetype"),
    url("./assets/fonts/GESansBold.svg#geSans_bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "geInsp_medium";
  src: url("./assets/fonts/GEInspMd.eot");
  src: url("./assets/fonts/GEInspMd.eot") format("embedded-opentype"),
    url("./assets/fonts/GEInspMd.woff2") format("woff2"),
    url("./assets/fonts/GEInspMd.woff") format("woff"),
    url("./assets/fonts/GEInspMd.ttf") format("truetype"),
    url("./assets/fonts/GEInspMd.svg#geInsp_medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "geInsp_italic";
  src: url("./assets/fonts/GEInspIt.eot");
  src: url("./assets/fonts/GEInspIt.eot") format("embedded-opentype"),
    url("./assets/fonts/GEInspIt.woff2") format("woff2"),
    url("./assets/fonts/GEInspIt.woff") format("woff"),
    url("./assets/fonts/GEInspIt.ttf") format("truetype"),
    url("./assets/fonts/GEInspIt.svg#geInsp_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  min-height: 100%;
  height: 100%;
}
body {
  margin: 0;
  background: #e7e8ea;
  height: 100%;
  font-size-adjust: none !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: "geSans_regular", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  list-style: none;
}

.font-ge {
  font-family: "geSans_regular", Arial, Helvetica, sans-serif !important;
}

.ge-blue {
  background-color: #005eb8;
}

.ge-dark-indigo {
  background-color: #0c2340;
}

.px-5 {
  padding-right: 6.5rem !important;
  padding-left: 5rem !important;
}

.icons,
.icon-before::before,
.crumbs .breadcrumb li a:before {
  background: url(./assets/images/icon-sprites@2x.png) no-repeat;
  background-size: 144px auto;
  display: inline-block;
}

.icon-before::before {
  content: "";
  display: block;
  position: absolute;
}

/* overriding default chart export menu position */
.amcharts-export-menu-bottom-right {
  bottom: 4px !important;
  right: -20px !important;
}
